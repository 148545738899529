import { ShoppingOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../redux-utils/store";

const CartIcon = () => {
  const itemsCount = useSelector(
    (state: AppState) => state.cart?.items?.length
  );

  const cartIconClick = React.useCallback(() => {
    window.woopra.track(`cart_icon:click`, {
      url: window.location.pathname,
      title: document.title,
    });
  }, []);

  return (
    <Link to="/cart" onClick={cartIconClick}>
      <Button type="link" className="cart-icon-button">
        <ShoppingOutlined style={{ fontSize: 20 }} />
        <Badge
          className="cart-icon-badge"
          count={itemsCount}
          size="small"
          // offset={[36, -20]}
        />
      </Button>
    </Link>
  );
};

export default React.memo(CartIcon);
