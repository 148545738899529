import { Button, Modal } from "antd";
import { useState } from "react";

const SizeChartModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    window.woopra.track("size_chart_link:click", {
      url: window.location.pathname,
      title: document.title,
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <span className="size-chart small-font gray-text" onClick={showModal}>
        Size chart
      </span>
      <Modal
        title="Size Chart"
        className="size-chart-modal"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="okay" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <table className="size-chart-table">
          <tbody>
            <tr>
              <th>Size</th>
              <th>Chest (in)</th>
              <th>Front length (in)</th>
              <th>Across shoulder (in)</th>
              <th>Sleeve length (in)</th>
            </tr>
            <tr>
              <td>M</td>
              <td>40</td>
              <td>27</td>
              <td>17</td>
              <td>7.5</td>
            </tr>
            <tr>
              <td>L</td>
              <td>42</td>
              <td>28</td>
              <td>18</td>
              <td>8</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>44</td>
              <td>29</td>
              <td>18.5</td>
              <td>8.5</td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default SizeChartModal;
