import Axios from "axios";

export const checkLogin = () => {
  return Axios.get("https://thestupefiant.com/backend/check_login.php");
};

export const profile = () => {
  return Axios.post("https://thestupefiant.com/backend/profile.php");
};

export const login = (email: string, password: string) => {
  return Axios.post(
    "https://thestupefiant.com/backend/login.php",
    new URLSearchParams({
      email,
      password,
    })
  );
};

export const register = (
  fname: string,
  lname: string,
  email: string,
  password: string
) => {
  return Axios.post(
    "https://thestupefiant.com/backend/register.php",
    new URLSearchParams({
      fname,
      lname,
      email,
      password,
    })
  );
};

export const logout = () => {
  return Axios.post("https://thestupefiant.com/backend/logout.php");
};
