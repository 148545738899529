import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const TermsAndConditions = () => {
  return (
    <Typography>
      <Title level={4}>Terms & Conditions</Title>
      <Title level={4}>General Terms</Title>
      <Paragraph>
        By accessing and placing an order with, you confirm that you are an
        agreement with and bound by the terms of service contained in the Terms
        & Conditions outlined below. These terms apply to the entire website and
        any email or other type of communication between you.
      </Paragraph>
      <Paragraph>
        Under no circumstances shall team be liable for any direct, indirect,
        special, incidental or consequential damages, including, but not limited
        to loss of data or profit, arising out of the use, or the inability to
        use, the materials on this site, even team or an authorized
        representative has been advised of the possibility of such damages. If
        your use of materials from this site results in the need for servicing
        repair or correction of equipment or data, you assume any costs thereof
        will not be responsible for any outcome that may occur during the course
        of usage of our resources. We reserve the rights to change prices and
        revise the resources usage policy in any moment.
      </Paragraph>
      <Title level={4}>License</Title>
      <Paragraph>
        Stupefiant grants you a revocable, non-exclusive, non-transferable,
        limited license to download, install and use our service strictly in
        accordance with the terms of this Agreement.
      </Paragraph>
      <Paragraph>
        These Terms & Conditions are a contract between you and stupefiant
        (referred to in these Terms & Conditions as "Stupefiant", "us", "we" or
        "our".), the provider of the Stupefiant website and the services
        accessible from the Stupefiant website (which are collectively referred
        to in these Terms & Conditions as the Stupefiant Service)
      </Paragraph>
      <Paragraph>
        You are agreeing to be bound by these Terms & Conditions. If you do not
        agree to these Terms & Conditions, please do not use the Service. In
        these Terms & Conditions, "you" refers both to you as an individual and
        to the entity you represent. If you violate any of these Terms &
        Conditions We reserve the right to cancel your account or block access
        to your account without notice.
      </Paragraph>
      <Title level={4}>Definitions and key terms</Title>
      <Paragraph>For this Terms & Conditions:</Paragraph>
      <Paragraph>
        Cookie: small amount of data generated by a website and saved by your
        web browser it is used to identify your browser, provide analytics,
        remember information about you such as your language preference or login
        information
      </Paragraph>
      <Paragraph>
        Company: when this policy mentions "Company" "we," "us" or "our", it
        refers to Stupefiant Enterprise, Plot No 381 B.J.B Nagar, Bhubaneswar
        that is possible for your information under this Privacy Policy
      </Paragraph>
      <Paragraph>
        Country: where Stupefiant or the owners/founders of stupefiant are
        based, in this case it is india.
      </Paragraph>
      <Paragraph>
        Customer: refers to the company, organization or person that signs up to
        use the stupefiant service to manage the relationships with your
        consumers or service users.
      </Paragraph>
      <Paragraph>
        Device: any internet connected device such as a phone, tablet,computer
        any other device that can be used to visit Stupefiant and use the
        services.
      </Paragraph>
      <Paragraph>
        IP address: Every dice connected to the Internet in assigned a number
        known as an internet protocol (IP) address. These numbers are usually
        assigned in geographic blocks. An IP address can often be used to
        identity the location from which a device is connecting to the internet.
      </Paragraph>
      <Paragraph>
        Personnal: refers to those individuals who are employed by Stupefiant or
        are under contract to perform a service on behalf of one of the parties.
      </Paragraph>
      <Paragraph>
        Personal Data: any information that directly, indirectly, or in
        connection with other information including a personal Identification
        number allows for the identification or identifiability of a natural
        person.
      </Paragraph>
      <Paragraph>
        Service: refers to the service provided by Stupefiant as described in
        the relative terms (if available) and on this platform.
      </Paragraph>
      <Paragraph>
        Third-party service: refers to advertisers contest sponsors, promotional
        and marketing partners, and others who provide our content or whose
        products or services we think may interest you.
      </Paragraph>
      <Paragraph>
        Website: Stupefiant's site, which can be accessed via this URL:
        www.stupefiant.in
      </Paragraph>
      <Paragraph>
        You: a person or entity that in registered with Stupefiant to use the
        Services.
      </Paragraph>
      <Title level={4}>Restrictions</Title>
      <Paragraph>
        You agree not to, and you will not permit others to:
        <ul>
          <li>
            License, sell, rent, lease, assign, distribute, transmit, host,
            outsource, disclose or otherwise commercially exploit the service or
            make the platform available to any third party.
          </li>
          <li>
            Modify, make derivative works of disassemble, decrypt reverse
            compile or reverse engineer any part of the sservice
          </li>
          <li>
            Remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) of or its affiliates, partners,
            suppliers or the licensors of the service.
          </li>
        </ul>
      </Paragraph>
      <Title level={4}>Payment</Title>
      <Paragraph>
        if you pay for any of our one-time payment plans, you agree to pay all
        fees or charges to your account for the Service in accordance with the
        fees changes and billing terms in affect at the time that each fee or
        charges due and payable. Your Payment Provider agreement governs your
        use of the designated credit card/Debit Card account, and you must refer
        to that agreement and not these Terms to determine your rights and
        abilities with respect to your Payment Provider. By providing us with
        your credit card/Debit Card number and associated payment information,
        you agree that we are authorized to verify information immediately, and
        subsequently voice your account for all fees and charges due and payable
        to us here under and that no additional notice or consent a required.
        You agree to immediately notify us of any change in your billing address
        or the Credit card/Debit Card/UPI used for payment hereunder. We reserve
        the right at any time to change its prices and billing methods, either
        immediately upon posting on our Site or by e-mail delivery to your
        organization's administrator(s).
      </Paragraph>
      <Paragraph>
        Any attorney fees, court costs or other costs incurred in collection of
        delinquent unimputed amounts shall be the responsibility of and paid for
        by you. No contracts will exist between you and us for the Service until
        we accept your order by a confirmatory email, SMS/MMS message, or other
        appropriate means of communication you are responsible for any
        third-party fees that you may incur when using the Service.
      </Paragraph>
      <Title level={4}>Return and Refund Policy</Title>
      <Paragraph>
        Thanks for shopping with us. We appreciate the fact that you like to buy
        the stuff we build. We also want to make sure you have a rewarding
        experience while you're exploring, evaluating, and purchasing our
        products. As with any shopping experience there are terms and conditions
        that apply to transactions as our company We'll be as brief as our
        attorneys will allow. The minimum thing to remember is that by placing
        an order or making a purchase from us, you agree to the terms along with
        our Privacy Policy.
      </Paragraph>
      <Paragraph>
        If for any reason, you are not completely satisfied with any good or
        service that we provide, don't hesitate to contact us and we will
        discuss any of the later you are going through with our product.
      </Paragraph>
      <Title level={4}>Your Suggestions</Title>
      <Paragraph>
        Any feedback, comments, ideas, improvements or suggestions
        (collectively, "suggestions provided by you to us with respect to the
        service shall remain the sole and exclusive property of us. We shall be
        free to use, copy, modify, push, or rediebuse the Suggestions for any
        purpose and at any way without any credit or any compensation to you.
      </Paragraph>
      <Title level={4}>Your Consent</Title>
      <Paragraph>
        We've updated our Terms & Conditions to provide you with complete
        transparency into what is being set when you visit our site and how it's
        being used. By using our service, registering an account, or making a
        purchase, you hereby consent to our Terms & Conditions.
      </Paragraph>
      <Title level={4}>Cookies</Title>
      <Paragraph>
        We use "Cookies only the areas of our website that you have vislied. A
        Cookie is a small piece of data stored on your computes or mobile device
        by your web browser. We use Cookies to enhance the performance and
        functionality of our service but are non-essential to their ute However,
        without these cookies certain functionality like videos may become
        unavailable or you would be required to enter your ingin deta every time
        you via our platform as we would not be able to remember that you had
        logged in previously Most web browsers can be set to disable the use of
        Cookies. However if you disable Cookes you may not be able to access
        functionally on our website correctly or at all, We never place
        Personally identifiable information Cookies
      </Paragraph>
      <Title level={4}>Changes To Our Terms & Conditions</Title>
      <Paragraph>
        You acknowledge and agree that we may stop (permanently or temporarily)
        providing the service (or any features within the service) to you or to
        users generally at our sole discretion, without prior notice to you You
        may stop using the Service at any time you do not need to specifically
        inform us when you stop using the Service. You acknowledge and agree
        that if we disable access to your account you may be prevented from
        accessing the Service, your account details or any files ar other
        materials which is contained in your account. If we decide to change our
        Terms & Conditions, we will post those changes on this page, and/or
        update the Terms & Conditions modification date below.
      </Paragraph>
      <Title level={4}>Modifications to Our service</Title>
      <Paragraph>
        We reserve the right to modify, suspend or discontinue, temporarily or
        permanently the service or any service to which it connects, with or
        without notice and without liability to you.
      </Paragraph>
      <Title level={4}>Updates to Our service</Title>
      <Paragraph>
        We may from time to time provide enhancements or improvements to the
        features/ functionality of the service, which may include patches, bug
        fixes, updates, upgrades and other modifications (Updates). Updates may
        modify or delete certain features and/or functionalities of the service.
        You agree that we have no obligation to (i) provide any Updates or)
        contine to provide noble any particular features and/ functionalities of
        the service to you. You further agree that an Updates will be deemed to
        constitute an integral part of the service, and subject to the terms and
        conditions of this Agreement.
      </Paragraph>
      <Title level={4}>Third-Party Services</Title>
      <Paragraph>
        We may display, include or make available third party content (including
        datas, information, applications and other products services or provide
        links to third party websites or services (Third Party Services"). You
        acknowledge and agree that we shall not be responsible for any Third
        Party Services, including their accuracy completeness, timliness,
        validity, copyright compliance legality, decency, quality or any other
        aspect thereof. we do not assume and shall not have any liability or
        responsibility to you or any other person or entity for any Third Party
        Services Third Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and condition.
      </Paragraph>
      <Title level={4}>Term and Termination</Title>
      <Paragraph>
        This Agreement shall remain in effect until terminated by you or us. We
        may, in a sole discretion, at any time and for any or no reason, suspend
        or terminate this Agreement with or without prior notice. This Agreement
        will terminate immediately, without prior notice from us in the event
        that you fail to comply with any provision of the Agreement. You may
        also terminate this Agreement by deleting the service and all copies
        thereof from your computer Upon termination of Agreement, you shall
        cease all use of the cancel and delete all copies of the service from
        your computer. Termination of this Agreement will not limit any of our
        rights or remedies at law or in equity in case of breach by you (during
        the term of this Agreement) of any of your obligations under the present
        Agreement.
      </Paragraph>
      <Paragraph>
        If you are a copyright owner or such owner's agent and believe any
        material from us constitutes an infringement on your copyright, please
        contact us setting forth the following information: (a) a physical or
        electronic nature of the copyright owner or a person authorized to act
        on his behalf, (b) identification of the material that is aimed to he
        infringing; (c) your contact information, including your address,
        telephone number, and an email; (d) a statement by you that you have a
        good faith belief that use of the material is not authorized by the
        copyright owners and (e) the a statement that the information in the
        notification is accurate, and under penalty of perjury you are
        authorized to act on behalf of the owner.
      </Paragraph>
      <Title level={4}>Indemnification</Title>
      <Paragraph>
        You agree to indemnify and hold us and our parents subsidiaries,
        affiliates, officers, employees, agents, partners and licensors if any)
        harmless from any claim or demand, including reasonable attorney fees,
        due to or arising out of your (a) use of the service (b) violation of
        this Agreement or any law or regulation; or (c) violation of any right
        of a third party.
      </Paragraph>
      <Title level={4}>No Warranties</Title>
      <Paragraph>
        The service is provided to you “AS” “IS” and “AS AVAILABLE" With All
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law we, on our own behalf and on behalf of
        our affiliates and our respective licensors and service providers,
        expressly disclaims all warranties, whether express, implied, statutory
        or otherwise, with respect to the service, including all implied
        warranties of mechantability, firmness for a particular purpose, title
        and not- infringement, and warranties that may arise out of course of
        dealing, course of performance usage or trade practice.
      </Paragraph>
      <Paragraph>
        Without limitation to the foregoing, we provide no warranty or
        undertaking and makes no representation of any and that the service will
        meet your requirements achieve any intended results, be compatible or
        work with any other software websites, systems or services, operate
        without interruption meet any performance or reliability standards or be
        error free or that any errors or defects can or will be corrected.
      </Paragraph>
      <Paragraph>
        Without limiting the foregoing, neither us nor any provider make any
        reparation or warranty of any kind, express or info as to the operation
        or availability of ine service, or the information content and materials
        or products included thereons (ii) in that the service will be
        uninterupted or error-free (iii) as to the accuracy, reliability, or
        currency of any information or content provided through the service, or
        (iv) that the service, its servers the content, or e-mails sent from or
        on behalf of us are free of viruses, scripts, trojan horses, worms,
        malware, timebombs of other harmful components. Some jurisdictions do
        not allow the exclusion of or limitations on implied warranties or the
        limitations on the applicable statutory rights of a consumer, so some or
        all of the above exclusions and limitations may not apply to you.
      </Paragraph>
      <Title level={4}>Limitation of Liability</Title>
      <Paragraph>
        Not withstanding any damages that you might out the entire lability of
        us and any of our suppliers under any provision of this Agreement and
        your exclusive remedy for all of the foregoing shall be limited to the
        amount actually paid by you for the service. To the maximum extent
        permitted by applicable law in no event shall we or our suppliers be
        liable for any special, incidental, indirect, or consequential damages
        whatsoever (including but not limited so damages for loss of profits,
        for loss of data or other information, for business interruption, for
        personal injury, for lost of privacy an signing out of or in any way
        related to the use of or inability to use the service, third-party
        software and/or third-party hardware used with the service, or otherwise
        in connection with any provision of this Agreement), even if we or any
        supplier has been advised of the possibility of such damages and even
        the remedy fails of its essential purpose Some states/jurisdictions do
        not allow the exclusion or imitation of Incidental or consequential
        damages, so the above limitation or exclusion may not apply to you.
      </Paragraph>
      <Title level={4}>Severability</Title>
      <Paragraph>
        if any provision of this Agreement is held to be unenforceable or
        invalid, such provision will be charged and interpreted to accomplish
        the objectives of such provision to the greatest extent possible under
        applicable law and the remaining provision will continue in full force
        and effect.
      </Paragraph>
      <Paragraph>
        This Agreement, together with the privacy Policy and any other legal
        notices published by us on the Services, shall constitute the entire
        agreement between you and us concerning the Services. If any promion of
        this Agreement is deemed invalid by a court of competent juridiction,
        the invalidity of such provision shall not affect the sildy of the
        remaining provisions of this Agreement, which shall remain in full force
        and effect. No waiver of any term of this Agreement shall be deemed a
        further continuing waiver of such term or any other term, and our
        Failure to assert any right or provision under this Agreement shall not
        constitute a waiver of such right or provision. YOU AND US AGREE THAT
        ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST
        COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
        OTHERWISE SUCH CAUSE OF ACTION PERMANENTLY BANNED.
      </Paragraph>
      <Title level={4}>Waiver</Title>
      <Paragraph>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Agreement shall not effect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall be the waiver of a breach constitute
        waiver of any subsequent breach.
      </Paragraph>
      <Paragraph>
        No failure to exercise, and no delay in exercising, on the part of
        either party any right or any power under this Agreement shall operate
        at a waiver of that right or power. Nor shall any single or partial
        exercise of any right or power under this Agreement preclude further
        exercise of that or any other right granted herein. In the event of a
        conflict between this Agreement and any applicable purchase or others,
        the terms of this Agreement shall govern.
      </Paragraph>
      <Title level={4}>Amendments to this Agreement</Title>
      <Paragraph>
        We reserve the right, at its sole discretion, to modify or replace the
        Agreement at any time. If a revision is material we will provide at
        least 30 days notice prior to any new terms taking effect, What
        constitutes a materal change will be determined at our sole discretion.
        By continuing to access or use our service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use our service.
      </Paragraph>
      <Title level={4}>Entire Agreement</Title>
      <Paragraph>
        The Agreement constitutes the entire agreement between you and us
        regarding your use of the service and supersedes all prior and
        contemporaneous witten or oral agreements between you and us. You may be
        subject to additional terms and conditions that apply when you use or
        purchase other services from us, which we will provide to you at the
        time of such use or purchase.
      </Paragraph>
      <Title level={4}>Updates to Our Terms</Title>
      <Paragraph>
        We may change our service and policies, and we may need to make changes
        to these Terms so that they accurately reflect our service and policies
        Unless otherwise required by law, we will notify you (for example,
        through our Service) before we make changes to these Terms and give you
        an opportunity to review them before they go into effect. Then, if you
        continue to use the Service, you will be bound by the updated Terms. If
        you do not want to agree to these or any updated Terms, you can delete
        your account.
      </Paragraph>
      <Title level={4}>Intellectual Property</Title>
      <Paragraph>
        Our platform and its entire contents, features and functionality
        (including but not limited to all information software, text, displays,
        images, video and audio, and the design, selection and arrangement
        thereof), are owned by us, its censors or other providers of such
        matemal and are protected by and international copyright, trademark,
        patent, trade secret and other intellectual property or proprietary
        rights laws. The material may not be copied, modified, reproduced,
        downloaded or distributed in any way, in whole or in port, without the
        express prior written permission of us, unless and except as is
        expressly provided in these Terms & Conditions, Any unauthorized use of
        the material is prohibited.
      </Paragraph>
      <Title level={4}>Agreement to Arbitrate</Title>
      <Paragraph>
        This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A DISPUTE
        RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF: REGARDING THE
        ENFORCEMENT OR VALIDITY OF YOUR OR'S INTELLECTUAL PROPERTY RIGHTS. The
        term "dispute" means any dispute, action, or other controversy between
        you and us concerning the Services or this agreement, whether in
        contract, warranty, tort statute, regulation, ordinance, or any other
        legal or equitable basis. "Dispute wil be given the broadest possible
        meaning alowable under law.
      </Paragraph>
      <Title level={4}>Notice of Dispute</Title>
      <Paragraph>
        In the event of a dispute, you or us must give the other Notice of
        Dispute, which is a written statement that sets forth the name, address
        and contact information of the party giving it, the facts giving rise to
        the dispute, and the relief requested. You must send any Notice of
        Dispute vid email to: We will send any Notice of Dispute to you by mail
        to your address if we have it, or otherwise to your email address You
        and us will attempt to resolve any dispute through informal negotiation
        within sixty (60) days from the date the Notice of Dispute is sent.
        After sixty (60) days you or us may commence arbitration.
      </Paragraph>
      <Title level={4}>Binding Arbitration</Title>
      <Paragraph>
        if you and us don't resolve any dispute by informal negotiation, any
        other effort to resolve the dispute will be conducted exclusively by
        binding arbitration as described in this section, you are giving up the
        right to litigate (or participate as a party or class member; all
        disputes in court before a judge or jury: The depute shall be settled by
        binding arbitration in accordance with the commercial arbitration rules.
        Either party may seek any interior preliminary injunctive rate from any
        court of competent jurisdiction as necessary to protect the party's
        rights or property pending the completion of arbitration. Any and all
        legal, accounting, and other costs, fees, and expenses incurred by the
        prevailing party shall be borne by the non- prevailing party.
      </Paragraph>
      <Title level={4}>Submissions and Privacy</Title>
      <Paragraph>
        In the event that you submit or post any ideas, creative suggestions,
        designs photographs, formation, advertisements, data or proposals
        including ideas for new or improved products, services, features.
        technologies or promotions, you expressly agree that such submissions
        will automatically be seated as non-confidential and non propreitary and
        will become the sole property of us without any compensation or credito
        you whatsoever. We and our affiliates shall have no obligations with
        respect to such submissions or posts and may use the ideas contained in
        such submissions or posts for any purposes in any medium in perpetuity
        including but not limited to, developing, manufacturing, and marketing
        products and services using such ideas.
      </Paragraph>
      <Title level={4}>Promotions</Title>
      <Paragraph>
        We may, from time to time, include contests, promitions. Aweepstakes of
        other activities ("Promotions") that require you to submit i or
        information concerning yourself. Please note that all Promotions may be
        governed by separate rules that may contain certain eligibility
        requirements, such as restrictions as to age and geographic location.
        You are responsible to and all Promotions rules to determine whether or
        not you are eligible to participate. If you enter any Promotion, you
        agree to abide by and to comply with all Promotions Rules. Additional
        terms and conditions may apply to purchases of goods or services on or
        through the Services, which terms and condnions are made a part of this
        Agreement by this reference.
      </Paragraph>
      <Title level={4}>Typographical Errors</Title>
      <Paragraph>
        In the event a product and/or service is listed at an incorrect price or
        with incorrect information due to typographical error, we shall have the
        right to refuse or cancel any orders placed for the product and/or
        service listed at the incorrect price. We shall have the right to refuse
        or cancel any such order whether or not the order has been confirmed and
        your credit/Debit card/UPI charged. If your credit/Debit card/UPI has
        already been charged for the purchase and your order is cancelled, we
        shall immediately issue a credit to your credit card/Debit card account
        or other payment account in the amount of the charge.
      </Paragraph>
      <Title level={4}>Miscellaneous</Title>
      <Paragraph>
        If for any reason a court of competent jurisdicson find any provision or
        portion of these Terms & Conditions to be unenforceable, the remainder
        of these Terms & Conditions will continue in full force and effect. Any
        waiver of any provision of these Terms & Conditions will be effective
        only if in writing and signed by an authorized representative of us. We
        will be entitled to injunctive or other equitable relief (thout the
        obligations of posting any bond or surety) in the event of any breach or
        anticipatory breach by you. We operate and control our Service from our
        offices in The Service is not intended for distribution to or use by any
        person or entity in any jurisdiction or country where such distribution
        or use would be contrary to low or regulation. Accordingly, those
        persons who choose to access our Service from other locations do so on
        the own initiative and are solely responsible for compliance with local
        laws, if and to the extent local lawn are applicable. These Terms &
        Conditions (which include and incorporate our Privacy Policy) contains
        the entire understanding, and supersedes all prior understandings
        between you and us concerning the subject matter, and cannot be changed
        or modified by you. The section headings used in this Agreement are for
        convenience only and will not be given any legal import.
      </Paragraph>
      <Title level={4}>Disclaimer</Title>
      <Paragraph>
        We are not responsible for any contest, code or any other impaction. We
        do not provide warranties or guarantees, in no event shall we be liable
        for any special, direct, indirect, consequential or incidental damages
        or any damages whatsoever, whether in an actions of contact, negligence
        or other sort, arising out of or in connection with the use of the
        Service or the contents of the Service We reserve the right to make
        additions, deletions, or modifications to the contents on the Service at
        any time without prior notice.
      </Paragraph>
      <Title level={4}>Contact Us</Title>
      <Paragraph>
        Don't hesitate to comact us if you have my questions
        <ul>
          <li>Via Email: stupefiant4@gmail.com</li>
        </ul>
      </Paragraph>
    </Typography>
  );
};

export default TermsAndConditions;
