import Axios from "axios";

export const createOrder = (orderAmount: string, orderCurrency: string) => {
  return Axios.post(
    "https://thestupefiant.com/backend/payments/create-order.php",
    new URLSearchParams({
      orderAmount,
      orderCurrency,
    })
  );
};

export const verifyPayment = (
  razorpay_order_id: string,
  razorpay_payment_id: string,
  razorpay_signature: string
) => {
  return Axios.post(
    "https://thestupefiant.com/backend/payments/verify-payment.php",
    new URLSearchParams({
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
    })
  );
};

export const updateOrderPaymentId = (order_id: string, payment_id: string) => {
  return Axios.post(
    "https://thestupefiant.com/backend/payments/update-order-payment-id.php",
    new URLSearchParams({
      order_id,
      payment_id,
    })
  );
};

export const placeOrderQuery = (
  fname: string,
  lname: string,
  phone: string,
  email: string,
  pincode: string,
  address: string,
  total: string,
  items: string,
  payment_mode: string,
  payment_id?: string
) => {
  const params = new URLSearchParams({
    fname,
    lname,
    phone,
    email,
    pincode,
    address,
    total,
    items,
    payment_mode,
  });

  if (payment_id) {
    params.append("payment_id", payment_id);
  }

  return Axios.post(
    "https://thestupefiant.com/backend/place_order.php",
    params
  );
};
