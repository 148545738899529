import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const Shipping = () => {
  return (
    <Typography>
      <Title level={4}>Shipping</Title>
      <Paragraph>
        Please allow up to 1-3 business days for order processing and
        verification. You will receive an email notification once the order has
        shipped. Orders placed over the weekend or on holidays will begin
        processing the next business day.
      </Paragraph>
      <Paragraph>All shipping rates include tax.</Paragraph>
      <Title level={4}>Return policy</Title>
      <Paragraph>
        Due to limited quantities and high order volume, we cannot make changes
        to an order once it has been placed. All sales are final.
      </Paragraph>
    </Typography>
  );
};

export default Shipping;
