import * as React from "react";
import { Button, Form } from "antd";
import Input from "antd/lib/input/Input";
import Password from "antd/lib/input/Password";
import { login } from "../../utils/profileRelatedQueries";

interface LoginResponse {
  status: string;
}

interface LoginProps {
  showRegisterHandler: () => void;
  showLoginSuccess: () => void;
  showLoginFailed: () => void;
  getProfileDetails: () => void;
}

const Login: React.FC<LoginProps> = (props) => {
  const [disableButtons, setDisableButtons] = React.useState(false);

  const onLogin = React.useCallback(
    (formValues) => {
      const email = formValues?.["email"] ?? "";
      const password = formValues?.["password"] ?? "";

      setDisableButtons(true);
      login(email, password)
        .then((res) => {
          const loginResponse: LoginResponse = res?.data;
          if (loginResponse?.status === "success") {
            props.showLoginSuccess();
            props.getProfileDetails();
          } else {
            props.showLoginFailed();
          }
          setDisableButtons(false);
        })
        .catch((err) => console.log("here", err));
    },
    [props]
  );

  return (
    <div className="login-container">
      <h2>Login</h2>
      <div className="login-form-container">
        <Form
          name="login-form"
          onFinish={onLogin}
          layout={"vertical"}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Enter your email id",
                pattern: new RegExp(
                  //eslint-disable-next-line
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
              },
            ]}
          >
            <Input placeholder="Email id" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Enter password",
                pattern: new RegExp(
                  //eslint-disable-next-line
                  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                ),
              },
            ]}
          >
            <Password placeholder="Password" />
          </Form.Item>
          <div className="login-btn-container">
            <Button
              disabled={disableButtons}
              type="primary"
              htmlType="submit"
              onClick={() => {}}
            >
              Login
            </Button>
            <Button
              disabled={disableButtons}
              onClick={props.showRegisterHandler}
            >
              Sign up
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
