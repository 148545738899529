import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux-utils/store";

declare global {
  interface Window {
    woopra: any;
  }
}

function init() {
  document.body?.children?.[1]?.remove();

  const RootApp = (
    <Provider store={store}>
      <App />
    </Provider>
  );
  const rootElement = document.getElementById("root");
  if (rootElement?.hasChildNodes()) {
    ReactDOM.hydrate(RootApp, rootElement);
  } else {
    ReactDOM.render(RootApp, rootElement);
  }
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
