import React from "react";

const FooterLogo = () => {
  return (
    <img
      src="https://thestupefiant.com/images/logos/stupefiant_logo_2.png"
      alt=""
      className="footer-logo"
    />
  );
};

export default React.memo(FooterLogo);
