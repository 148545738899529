import {
  Button,
  Carousel,
  Col,
  Image,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getProductDetails,
  getProductImages,
} from "../../utils/productDetailsPageQueries";
import { ShoppingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../redux-utils/reducers/cartReducer";
import PageLoader from "../Loaders/PageLoader";
import { AppState } from "../../redux-utils/store";
import Text from "antd/lib/typography/Text";
import SizeChartModal from "./SizeChartModal";
import { useNavigate } from "react-router-dom";

interface ProductImage {
  id: number;
  image_url: string;
}

export interface ProductDetail {
  id: number;
  label: string;
  description: string;
  price: number;
  image_url: string;
}

interface ProductStock {
  id: number;
  product_id: number;
  size_id: number;
  qty: number;
  size: string;
}

interface Product {
  details: ProductDetail;
  stock: ProductStock[];
}

const ProductDetails = () => {
  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [productDetail, setProductDetail] = useState<Product>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [stockId, setStockId] = useState(-1);
  const productId = params?.["id"] ? parseInt(params?.["id"]) : 0;
  const itemExistsInCart = useSelector((state: AppState) =>
    state.cart?.items?.some(
      (item) => item?.productId === productId && item?.stockId === stockId
    )
  );
  const isOutOfStock = productDetail?.stock?.every((s) => s?.qty < 1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getProductImages(productId)
      .then((res) => {
        const images: ProductImage[] = res.data;
        setProductImages(images);
      })
      .catch((err) => console.log("here", err));

    getProductDetails(productId)
      .then((res) => {
        const productDetails: Product = res.data;
        setStockId(productDetails?.stock?.find((s) => s?.qty > 0)?.id ?? -1);
        setProductDetail(productDetails);
        setLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, [productId, setStockId]);

  const addToCartClick = useCallback(() => {
    dispatch(cartActions.addToCart({ productId, stockId }));
    window.woopra.track("product_details:add_to_cart:click", {
      url: window.location.pathname,
      title: document.title,
      productId,
      productLabel: productDetail?.details?.label,
    });
  }, [dispatch, productId, stockId, productDetail]);

  const sizeSelectionChange = useCallback(
    (e: RadioChangeEvent) => {
      setStockId(e.target.value);
      window.woopra.track("product_details:size_select:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        productLabel: productDetail?.details?.label,
        size: productDetail?.stock?.find((s) => s?.id === e?.target?.value)
          ?.size,
      });
    },
    [productId, productDetail]
  );

  const onBuyNowClick = React.useCallback(() => {
    // const COUNTRY_CODE = "+91";
    // const PHONE_NO = "9078813658";
    // const REDIRECT_URL =
    //   window.location.hostname === "localhost"
    //     ? "http://localhost:3000/checkout"
    //     : "https://thestupefiant.com/checkout";
    if (!itemExistsInCart) {
      dispatch(cartActions.addToCart({ productId, stockId }));
      // window.open(
      //   `https://www.phone.email/auth/sign-in?countrycode=${COUNTRY_CODE}&phone_no=${PHONE_NO}&redirect_url=${REDIRECT_URL}`,
      //   "peLoginWindow",
      //   "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0, width=500, height=560, top=" +
      //     (window.screen.height - 600) / 2 +
      //     ", left=" +
      //     (window.screen.width - 500) / 2
      // );
    }
    // } else {
    //   window.open(
    //     `https://www.phone.email/auth/sign-in?countrycode=${COUNTRY_CODE}&phone_no=${PHONE_NO}&redirect_url=${REDIRECT_URL}`,
    //     "peLoginWindow",
    //     "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0, width=500, height=560, top=" +
    //       (window.screen.height - 600) / 2 +
    //       ", left=" +
    //       (window.screen.width - 500) / 2
    //   );
    // }
    window.woopra.track("product_details:buy_now:click", {
      url: window.location.pathname,
      title: document.title,
      productId,
      productLabel: productDetail?.details?.label,
    });
    navigate("/checkout");
  }, [dispatch, productId, stockId, itemExistsInCart, productDetail, navigate]);

  const goToBagClick = React.useCallback(() => {
    window.woopra.track("product_details:go_to_bag:click", {
      url: window.location.pathname,
      title: document.title,
      productId,
      productLabel: productDetail?.details?.label,
    });
  }, [productId, productDetail]);

  return (
    <div style={{ paddingBottom: 50 }}>
      {loading && <PageLoader />}
      {!loading && (
        <Row>
          <Col sm={24} md={12} className="width-100-percent">
            <Carousel
              className="product-details-image-container"
              dotPosition="top"
            >
              {productImages?.map((image) => (
                <Image
                  key={image?.id}
                  src={image?.image_url}
                  alt="Failed to load image"
                  loading="eager"
                />
              ))}
            </Carousel>
          </Col>
          <Col sm={24} md={12} className="width-100-percent">
            <div>
              <strong>{productDetail?.details?.label}</strong>
              <br />
              <span className="gray-text">
                ₹{productDetail?.details?.price}
              </span>
            </div>
            <br />
            <div>
              <strong>DESCRIPTION</strong>
              <br />
              <div
                className="product-description-container gray-text"
                dangerouslySetInnerHTML={{
                  __html: productDetail?.details?.description ?? "",
                }}
              />
            </div>
            <br />
            <div>
              <strong>SHIPPING</strong>
              <br />
              <div className="product-description-container">
                <ul>
                  <li>Dispatched within 1-2 business days.</li>
                  <li>Free shipping.</li>
                </ul>
              </div>
            </div>
            <br />
            <div>
              <div className="select-size-heading-container">
                <strong>SELECT SIZE</strong>
                <SizeChartModal />
              </div>
              {!isOutOfStock && (
                <Radio.Group
                  style={{ marginTop: 5 }}
                  size="small"
                  buttonStyle="solid"
                  value={stockId}
                  onChange={sizeSelectionChange}
                >
                  <Space size={"middle"}>
                    {productDetail?.stock?.map((stock) => (
                      <Radio.Button disabled={stock?.qty < 1} value={stock?.id}>
                        {stock?.size}
                      </Radio.Button>
                    ))}
                  </Space>
                </Radio.Group>
              )}
            </div>
            <br />
            <div className="product-details-btn-container">
              {isOutOfStock && <Text type="danger">OUT OF STOCK</Text>}
              {!isOutOfStock && (
                <>
                  {!itemExistsInCart && (
                    <Button
                      disabled={stockId === -1}
                      type="primary"
                      onClick={addToCartClick}
                    >
                      <ShoppingOutlined />
                      ADD TO BAG
                    </Button>
                  )}
                  {itemExistsInCart && (
                    <Link to="/cart" onClick={goToBagClick}>
                      <Button type="primary">
                        <ShoppingOutlined />
                        GO TO BAG
                      </Button>
                    </Link>
                  )}
                  <Button onClick={onBuyNowClick} className="buy-now-btn">
                    BUY NOW
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductDetails;
