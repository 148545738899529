import { createSlice } from "@reduxjs/toolkit";

interface ProductInfo {
  productId: number;
  stockId: number;
  qty: number;
}

export interface CartState {
  items: ProductInfo[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.items.push({
        productId: action?.payload?.productId,
        stockId: action?.payload?.stockId,
        qty: 1,
      });
    },
    reduceQty: (state, action) => {
      const idx = state.items.findIndex(
        (item) =>
          item?.productId === action?.payload?.productId &&
          item?.stockId === action?.payload?.stockId
      );
      idx >= 0 && state.items[idx].qty--;
    },
    increaseQty: (state, action) => {
      const idx = state.items.findIndex(
        (item) =>
          item?.productId === action?.payload?.productId &&
          item?.stockId === action?.payload?.stockId
      );
      idx >= 0 && state.items[idx].qty++;
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item?.productId === action?.payload?.productId &&
            item?.stockId === action?.payload?.stockId
          )
      );
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
