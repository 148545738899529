import React from "react";
import { Layout } from "antd";
import "./App.less";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppHeader from "./components/AppHeader/AppHeader";
import Home from "./components/Home/Home";
import Shop from "./components/Shop/Shop";
import ProductDetails from "./components/Shop/ProductDetails";
import CartPage from "./components/CartPage/CartPage";
import CheckoutPage from "./components/CheckoutPage/CheckoutPage";
import AppFooter from "./components/AppFooter/AppFooter";
import ContactUs from "./components/Home/ContactUs";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import TermsAndConditions from "./components/Home/TermsAndConditions";
import Shipping from "./components/Home/Shipping";
import AboutUs from "./components/Home/AboutUs";
import ScrollToTop from "./ScrollToTop";
import Profile from "./components/profile/Profile";
const { Content } = Layout;

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout className="layout">
        <AppHeader />
        <Content className="app-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:id" element={<ProductDetails />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Content>
        <AppFooter />
      </Layout>
    </Router>
  );
};

export default App;
