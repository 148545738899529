import * as React from "react";
import { Button, Form, Input } from "antd";
import Password from "antd/lib/input/Password";
import { register } from "../../utils/profileRelatedQueries";

interface RegisterResponse {
  status: string;
}

interface RegisterProps {
  showLoginHandler: () => void;
  showRegistrationSuccess: () => void;
  showRegistrationFailed: () => void;
}

const Register: React.FC<RegisterProps> = (props) => {
  const onRegister = React.useCallback(
    (formValues) => {
      const fname = formValues?.["fname"] ?? "";
      const lname = formValues?.["lname"] ?? "";
      const email = formValues?.["email"] ?? "";
      const password = formValues?.["password"] ?? "";

      register(fname, lname, email, password)
        .then((res) => {
          const registerResponse: RegisterResponse = res?.data;
          if (registerResponse?.status === "success") {
            props.showRegistrationSuccess();
          } else {
            props.showRegistrationFailed();
          }
          props.showLoginHandler();
        })
        .catch((err) => console.log("here", err));
    },
    [props]
  );

  return (
    <div className="register-container">
      <h2>Register</h2>
      <div className="register-form-container">
        <Form
          name="register-form"
          onFinish={onRegister}
          layout={"vertical"}
          autoComplete="off"
        >
          <Form.Item
            name="fname"
            rules={[
              {
                required: true,
                message: "Enter your first name",
                type: "string",
              },
            ]}
          >
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="lname"
            rules={[
              {
                required: true,
                message: "Enter your last name",
                type: "string",
              },
            ]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Enter your email id",
                pattern: new RegExp(
                  //eslint-disable-next-line
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
              },
            ]}
          >
            <Input placeholder="Email id" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Enter a strong password",
                pattern: new RegExp(
                  //eslint-disable-next-line
                  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                ),
              },
            ]}
          >
            <Password placeholder="Password" />
          </Form.Item>
          <div className="register-btn-container">
            <Button type="primary" htmlType="submit" onClick={() => {}}>
              Sign up
            </Button>
            <Button onClick={props.showLoginHandler}>Sign in</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Register;
