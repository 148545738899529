import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Space, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cartActions } from "../../redux-utils/reducers/cartReducer";
import { AppState } from "../../redux-utils/store";
import { getCartDetails } from "../../utils/cartPageQueries";
import PageLoader from "../Loaders/PageLoader";
import { ProductDetail } from "../Shop/ProductDetails";

interface StockDetail {
  id: number;
  product_id: number;
  size_id: number;
  qty: number;
  size: string;
}

export interface CartDetails {
  products: ProductDetail[];
  stocks: StockDetail[];
}

export interface CartProduct extends ProductDetail {
  stock_id: number;
  size: string;
  available_stock: number;
}

const CartPage = () => {
  const addedProductsInfo = useSelector((state: AppState) => state.cart?.items);
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const addedProductIds = addedProductsInfo
      ?.map((p) => p?.productId)
      ?.join(",");
    const addedStockIds = addedProductsInfo?.map((p) => p?.stockId)?.join(",");
    getCartDetails(addedProductIds, addedStockIds)
      .then((res) => {
        const cartDetails: CartDetails = res.data;
        const cartProducts: CartProduct[] = [];
        addedProductsInfo?.forEach((info) => {
          const productDetails = cartDetails?.products?.find(
            (product) => product?.id === info?.productId
          );
          const stockDetails = cartDetails?.stocks?.find(
            (stock) => stock?.id === info?.stockId
          );
          productDetails &&
            stockDetails &&
            cartProducts.push({
              ...productDetails,
              stock_id: stockDetails?.id,
              size: stockDetails?.size,
              available_stock: stockDetails?.qty,
            });
        });
        setCartProducts(cartProducts);
        setLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, [addedProductsInfo]);

  const reduceQty = useCallback(
    (productId: number, stockId: number) => {
      dispatch(cartActions.reduceQty({ productId, stockId }));
      window.woopra.track("cart_page:reduce_qty:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        stockId,
      });
    },
    [dispatch]
  );

  const increaseQty = useCallback(
    (productId: number, stockId: number) => {
      dispatch(cartActions.increaseQty({ productId, stockId }));
      window.woopra.track("cart_page:increase_qty:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        stockId,
      });
    },
    [dispatch]
  );

  const removeCartProduct = useCallback(
    (productId: number, stockId: number) => {
      setCartProducts((products) =>
        products?.filter(
          (product) =>
            !(product?.id === productId && product?.stock_id === stockId)
        )
      );
      dispatch(cartActions.removeFromCart({ productId, stockId }));
      window.woopra.track("cart_page:remove_item:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        stockId,
      });
    },
    [dispatch]
  );

  const subTotal = cartProducts?.reduce((total, product) => {
    const qty =
      addedProductsInfo?.find(
        (p) => p?.productId === product?.id && p?.stockId === product?.stock_id
      )?.qty ?? 0;
    return total + product?.price * qty;
  }, 0);
  // const shippingCharges = 50;
  const totalCharges = subTotal;

  const checkoutClick = React.useCallback(() => {
    window.woopra.track("cart_page:checkout_btn:click", {
      url: window.location.pathname,
      title: document.title,
    });

    // const COUNTRY_CODE = "+91";
    // const PHONE_NO = "9078813658";
    // const REDIRECT_URL = window.location.hostname === "localhost" ? "http://localhost:3000/checkout" : "https://thestupefiant.com/checkout";
    // window.open(
    //   `https://www.phone.email/auth/sign-in?countrycode=${COUNTRY_CODE}&phone_no=${PHONE_NO}&redirect_url=${REDIRECT_URL}`,
    //   "peLoginWindow",
    //   "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0, width=500, height=560, top=" +
    //     (window.screen.height - 600) / 2 +
    //     ", left=" +
    //     (window.screen.width - 500) / 2
    // );
  }, []);

  return (
    <>
      {loading && <PageLoader />}
      {!loading && cartProducts?.length === 0 && (
        <Result
          title={"Bag is empty!"}
          extra={
            <Link to="/shop">
              <Button type="primary">GO TO SHOP</Button>
            </Link>
          }
        />
      )}
      {!loading && cartProducts?.length > 0 && (
        <>
          <Row>
            <Col xs={24} md={12}>
              <div className="order-summary-heading">
                <strong>BAG</strong>
                <div>
                  <Link to={"/checkout"}>
                    <Button onClick={checkoutClick} type="primary">
                      CHECKOUT
                    </Button>
                  </Link>
                </div>
              </div>
              <table className="order-summary-table">
                <tbody>
                  <tr>
                    <th>Sub Total</th>
                    <td>₹{subTotal}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>₹{totalCharges}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <br />
          <table className="cart-page-list">
            <tbody>
              {cartProducts?.map((product) => {
                const qty =
                  addedProductsInfo?.find(
                    (p) =>
                      p?.productId === product?.id &&
                      p?.stockId === product?.stock_id
                  )?.qty ?? 0;
                const total = product?.price * qty;
                return (
                  <tr>
                    <td>
                      <img
                        className="product-card-image"
                        alt=""
                        src={product?.image_url}
                      />
                    </td>
                    <td>
                      <div className="cart-product">
                        <Link
                          className="product-label-link"
                          to={`/shop/${product?.id}`}
                        >
                          <strong className="small-font">
                            {product?.label}
                          </strong>
                        </Link>
                        <div>
                          <span className="small-font gray-text">
                            SIZE: {product?.size}
                          </span>
                        </div>
                        <div>
                          <Space>
                            {qty === 1 && (
                              <Tooltip title="Remove from cart">
                                <Button
                                  size="small"
                                  type="default"
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                  onClick={removeCartProduct.bind(
                                    null,
                                    product?.id,
                                    product?.stock_id
                                  )}
                                />
                              </Tooltip>
                            )}
                            {qty > 1 && (
                              <Tooltip title="Remove">
                                <Button
                                  size="small"
                                  type="default"
                                  shape="circle"
                                  icon={<MinusOutlined />}
                                  disabled={qty === 1}
                                  onClick={reduceQty.bind(
                                    null,
                                    product?.id,
                                    product?.stock_id
                                  )}
                                />
                              </Tooltip>
                            )}
                            <span>{qty}</span>
                            <Tooltip title="Add">
                              <Button
                                size="small"
                                type="default"
                                shape="circle"
                                icon={<PlusOutlined />}
                                disabled={qty === product?.available_stock}
                                onClick={increaseQty.bind(
                                  null,
                                  product?.id,
                                  product?.stock_id
                                )}
                              />
                            </Tooltip>
                          </Space>
                        </div>
                        <div>
                          <strong className="gray-text">₹{total}</strong>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default React.memo(CartPage);
