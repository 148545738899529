import {
  InstagramFilled,
  MenuOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Button, Drawer, DrawerProps, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { profile } from "../../utils/profileRelatedQueries";
import PinterestIcon from "../icons/PinterestIcon";
import { UserProfile } from "../profile/Profile";
import AppLogo from "./AppLogo";
import AppMenuItems from "./AppMenuItems";
import CartIcon from "./CartIcon";

const MenuDrawer = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [placement] = React.useState<DrawerProps["placement"]>("right");
  const [profileDetails, setProfileDetails] = React.useState<UserProfile>();

  const getProfileDetails = React.useCallback(() => {
    profile()
      .then((res) => {
        const userProfile: UserProfile = res?.data;
        setProfileDetails(userProfile);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const showDrawer = () => {
    setOpen(true);
    window.woopra.track("menu_btn:click", {
      url: window.location.pathname,
      title: document.title,
    });
  };

  const onClose = () => {
    setOpen(false);
    window.woopra.track("menu:close", {
      url: window.location.pathname,
      title: document.title,
    });
  };

  React.useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails, pathname]);

  return (
    <div className="menu-drawer-container">
      <Button type="link" size="large" onClick={showDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer
        placement={placement}
        width={500}
        onClose={onClose}
        visible={open}
        getContainer={false}
        className="menu-drawer"
        closeIcon={false}
        title={
          <div className="menu-drawer-title-container">
            <img
              alt=""
              src="https://thestupefiant.com/images/logos/stupefiant_logo_1.png"
              width={42}
            />
          </div>
        }
      >
        <div className="app-menu-drawer">
          <AppMenuItems closeMenu={onClose} profileDetails={profileDetails} />
          <div className="drawer-footer-follow-us">
            <Space>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/stupefiantindia?r=nametag"
              >
                <InstagramFilled style={{ fontSize: 22 }} />
              </a>
              <a rel="noreferrer" target="_blank" href="https://pin.it/5gdfiJY">
                <PinterestIcon height={20} width={22} />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/c/918456903759"
              >
                <WhatsAppOutlined style={{ fontSize: 18, marginBottom: 6 }} />
              </a>
            </Space>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const AppHeader = () => {
  return (
    <Header className="app-header">
      <div className="app-header-container">
        <div className="cart-icon-container">
          <Link to={"/profile"}>
            <Button type="link" className="profile-icon-button">
              <UserOutlined style={{ fontSize: 19 }} />
            </Button>
          </Link>
          <CartIcon />
        </div>
        <AppLogo />
        <MenuDrawer />
      </div>
    </Header>
  );
};

export default React.memo(AppHeader);
