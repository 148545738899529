import Axios from "axios";

export const getHomePageFeaturedImageUrls = () => {
  return Axios.get(
    "https://thestupefiant.com/backend/get_home_featured_images.php"
  );
};

export const getBestSellers = () => {
  return Axios.get("https://thestupefiant.com/backend/best_sellers.php");
};

export const subscribeNewsletters = (email: string) => {
  return Axios.post(
    "https://thestupefiant.com/backend/subscribe_newsletters.php",
    new URLSearchParams({ email })
  );
};
