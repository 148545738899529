import { InstagramFilled, WhatsAppOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, message } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import PinterestIcon from "../icons/PinterestIcon";
import FooterLogo from "./FooterLogo";
import { subscribeNewsletters } from "../../utils/homePageQueries";

enum ResponseStatusEnum {
  SUCCESS = "success",
  ERROR = "warning",
}

interface NewsletterSubscribeResponse {
  status: ResponseStatusEnum;
}

const AppFooter = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [subscribing, setSubscribing] = useState(false);
  const onFinishForm = useCallback(
    (formValues: any) => {
      const emailId = formValues["email_id"] ?? "";
      if (!emailId) {
        return;
      }
      setSubscribing(true);
      subscribeNewsletters(emailId)
        .then((res) => {
          const subNewslettersResponse: NewsletterSubscribeResponse = res.data;
          setSubscribing(false);
          if (subNewslettersResponse?.status === ResponseStatusEnum.SUCCESS) {
            window.woopra.track("subscribe_newsletters_success", {
              url: window.location.pathname,
              title: document.title,
              emailId,
            });
            messageApi.success("Subscribed to Newsletters");
          } else {
            window.woopra.track("subscribe_newsletters_failed", {
              url: window.location.pathname,
              title: document.title,
              emailId,
            });
            messageApi.warning("You are already subscribed.");
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          setSubscribing(false);
        });
    },
    [messageApi]
  );

  return (
    <Footer className="app-footer">
      {contextHolder}
      <FooterLogo />
      <Form onFinish={onFinishForm}>
        <div className="newsletters">
          <div className="info">
            <h4>SIGN UP FOR NEWSLETTERS</h4>
            <span>
              JOIN OUR COMMUNITY & BE THE FIRST TO RECEIVE OUR SPECIAL
            </span>
            <span>OFFERS & LATEST NEWS AND GET EXCLUSIVE UPDATES OF OUR</span>
            <span>UPCOMING PRODUCTS</span>
          </div>
          <Form.Item
            style={{ margin: 10 }}
            name="email_id"
            rules={[
              {
                required: true,
                message: "Enter a valid email id",
                pattern: new RegExp(
                  //eslint-disable-next-line
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <div className="join">
            <Form.Item style={{ margin: 0 }}>
              <Button disabled={subscribing} type="primary" htmlType="submit">
                {subscribing ? "Subscribing..." : "Join"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <ul className="footer-links-list">
        <li>
          <Link to={"/contact-us"}>CONTACT US</Link>
        </li>
        <li>
          <Link to={"/privacy-policy"}>PRIVACY POLICY</Link>
        </li>
        <li>
          <Link to={"/terms-and-conditions"}>TERMS & CONDITIONS</Link>
        </li>
        <li>
          <Link to={"/about-us"}>ABOUT US</Link>
        </li>
        <li>
          <Link to={"/shipping"}>SHIPPING</Link>
        </li>
        <li>
          <span>CONNECT WITH US</span>
        </li>
      </ul>
      <Space>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/stupefiantindia?r=nametag"
        >
          <InstagramFilled style={{ fontSize: 18, marginBottom: 5 }} />
        </a>
        <a rel="noreferrer" target="_blank" href="https://pin.it/5gdfiJY">
          <PinterestIcon height={18} width={18} />
        </a>
        <a rel="noreferrer" target="_blank" href="https://wa.me/c/918456903759">
          <WhatsAppOutlined style={{ fontSize: 18, marginBottom: 6 }} />
        </a>
      </Space>
      <div className="copyright-container">
        <strong>© 2023, Stupéfiant</strong>
      </div>
    </Footer>
  );
};

export default React.memo(AppFooter);
