import { Button, Carousel } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getHomePageFeaturedImageUrls } from "../../utils/homePageQueries";
import BestSellers from "./BestSellers";

interface FeaturedImage {
  id: number;
  image_url: string;
  label: string;
  description: string;
  product_id: number;
}

// const COVER_IMAGES = [
//   "https://thestupefiant.com/images/cover/collections.jpg",
// ];

const Home = () => {
  const [featuredImages, setFeaturedImages] = useState<FeaturedImage[]>([]);

  React.useEffect(() => {
    getHomePageFeaturedImageUrls()
      .then((res) => {
        const images: FeaturedImage[] = res.data;
        setFeaturedImages(images);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const shopNowClick = React.useCallback(() => {
    window.woopra.track("home_page:shop_now_btn:click", {
      url: window.location.pathname,
      title: document.title,
    });
  }, []);

  return (
    <>
      {/* <video className="intro-video-square" autoPlay loop>
        <source src="https://thestupefiant.com/videos/intro_square.mp4" type="video/mp4" />
      </video>
      <video className="intro-video-landscape" autoPlay loop>
        <source src="https://thestupefiant.com/videos/intro_landscape.mp4" type="video/mp4" />
      </video> */}
      <div className="carousel-container">
        <div className="new-arrivals-container">NEW ARRIVALS</div>
        <Carousel autoplay>
          {featuredImages?.map((image) => (
            <div key={image?.id} className="carousel-image-container">
              <Link to={`/shop/${image?.product_id}`}>
                <img src={image?.image_url} alt="" loading="eager" />
              </Link>
            </div>
          ))}
        </Carousel>
        <div className="shop-btn-container">
          <Link to={"/shop"} onClick={shopNowClick}>
            <Button type="primary">SHOP NOW</Button>
          </Link>
        </div>
        <BestSellers />
        {/* <Carousel autoplay>
          {COVER_IMAGES?.map((image, idx) => (
            <div key={idx + 1} className="cover-image-container">
              <img src={image} alt="" loading="eager" />
            </div>
          ))}
        </Carousel> */}
      </div>
    </>
  );
};

export default React.memo(Home);
