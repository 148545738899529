import {
  CheckSquareFilled,
  ContactsFilled,
  CreditCardFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  // Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Result,
  Row,
  Steps,
} from "antd";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cartActions } from "../../redux-utils/reducers/cartReducer";
import { AppState } from "../../redux-utils/store";
import { getCartDetails } from "../../utils/cartPageQueries";
import {
  createOrder,
  placeOrderQuery,
  verifyPayment,
} from "../../utils/checkoutPageQueries";
import { profile } from "../../utils/profileRelatedQueries";
import { CartDetails, CartProduct } from "../CartPage/CartPage";
import PageLoader from "../Loaders/PageLoader";
import { UserProfile } from "../profile/Profile";
// import * as jose from "jose";
import { ResultStatusType } from "antd/lib/result";

function loadScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

interface PaymentStatus {
  success: boolean;
  reason?: string;
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

enum ResponseStatusEnum {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface PlaceOrderResponse {
  status: ResponseStatusEnum;
  reason: string;
  stock_id?: number;
  order_id?: number;
}

interface PlaceOrderStatus {
  status: ResultStatusType;
  title: string;
  subTitle?: string | ReactNode;
}

interface RazorpayPayment {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

enum PaymentMode {
  ONLINE = "online",
  PAY_ON_DELIVERY = "pay_on_delivery",
}

// const API_KEY = "4BVDYgoeWg2Na1OIE3GW8Vj3j8HKUGAM";

const CheckoutPage: React.FC = () => {
  const [form] = Form.useForm();

  // verified user details
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // Access specific query parameters
  // const param1 = queryParams.get("phtoken");
  // const [data, setData] = React.useState<jose.JWTPayload | null>(null);
  const [paymentMode, setPaymentMode] = React.useState<PaymentMode>(
    PaymentMode.ONLINE
  );

  // useEffect(() => {
  //   if (!param1) {
  //     window.location.href = "/";
  //   }

  //   const decodeToken = async (token: string) => {
  //     const secret = new TextEncoder().encode(API_KEY);
  //     try {
  //       const { payload } = await jose.jwtVerify(token, secret);
  //       console.log("here", payload);
  //       form.setFieldsValue({
  //         phone_number: payload?.phone_no,
  //       });
  //       setData(payload);
  //     } catch (e) {
  //       console.log(e);
  //       window.location.href = "/";
  //     }
  //   };

  //   param1 && decodeToken(param1);
  // }, [param1, form]);
  // verified user details

  const [currentStep, setCurrentStep] = React.useState(0);
  const [contactInfo, setContactInfo] = React.useState(undefined);
  const addedProductsInfo = useSelector((state: AppState) => state.cart?.items);
  const [loading, setLoading] = React.useState(true);
  const [cartProducts, setCartProducts] = React.useState<CartProduct[]>([]);
  const [placeOrderLoading, setPlaceOrderLoading] = React.useState(false);
  const [placeOrderStatus, setPlaceOrderStatus] =
    React.useState<PlaceOrderStatus>();
  const [profileDetails, setProfileDetails] = React.useState<UserProfile>();
  const dispatch = useDispatch();

  const onFinishForm = React.useCallback((formValues: any) => {
    setContactInfo(formValues);
    setCurrentStep(1);
  }, []);

  const getProfileDetails = React.useCallback(() => {
    setLoading(true);
    setProfileDetails(undefined);
    profile()
      .then((res) => {
        const userProfile: UserProfile = res?.data;
        setProfileDetails(userProfile);
        setLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, []);

  React.useEffect(() => {
    const addedProductIds = addedProductsInfo
      ?.map((p) => p?.productId)
      ?.join(",");
    const addedStockIds = addedProductsInfo?.map((p) => p?.stockId)?.join(",");
    getCartDetails(addedProductIds, addedStockIds)
      .then((res) => {
        const cartDetails: CartDetails = res.data;
        const cartProducts: CartProduct[] = [];
        addedProductsInfo?.forEach((info) => {
          const productDetails = cartDetails?.products?.find(
            (product) => product?.id === info?.productId
          );
          const stockDetails = cartDetails?.stocks?.find(
            (stock) => stock?.id === info?.stockId
          );
          productDetails &&
            stockDetails &&
            cartProducts.push({
              ...productDetails,
              stock_id: stockDetails?.id,
              size: stockDetails?.size,
              available_stock: stockDetails?.qty,
            });
        });
        setCartProducts(cartProducts);
        setLoading(false);
        getProfileDetails();
      })
      .catch((err) => console.log("here", err));
  }, [addedProductsInfo, getProfileDetails]);

  const subTotal = cartProducts?.reduce((total, product) => {
    const qty =
      addedProductsInfo?.find(
        (p) => p?.productId === product?.id && p?.stockId === product?.stock_id
      )?.qty ?? 0;
    return total + product?.price * qty;
  }, 0);
  // const shippingCharges = 50;
  const totalCharges = subTotal;

  const placeOrderUtil = React.useCallback(
    async (
      fname: string,
      lname: string,
      phone: string,
      email: string,
      pincode: string,
      address: string,
      city: string,
      state: string,
      total: string,
      items: any[],
      paymentId?: string
    ) => {
      try {
        const placeOrderResult = await placeOrderQuery(
          fname,
          lname,
          phone,
          email,
          pincode,
          [address, city, state].join(", "),
          total,
          JSON.stringify(items),
          paymentMode,
          paymentId
        );
        const placeOrderResponse: PlaceOrderResponse = placeOrderResult?.data;
        setPlaceOrderStatus({
          status:
            placeOrderResponse?.status === ResponseStatusEnum.SUCCESS
              ? ResponseStatusEnum.SUCCESS
              : placeOrderResponse?.stock_id
              ? ResponseStatusEnum.WARNING
              : ResponseStatusEnum.ERROR,
          title: placeOrderResponse?.reason,
          subTitle:
            placeOrderResponse?.status === ResponseStatusEnum.SUCCESS ? (
              <div>
                <p>
                  Please keep the order number and payment id as a reference. We
                  will be sending you the details shortly via sms/email.
                </p>
                <ul>
                  <li>
                    <strong>Order number</strong> :{" "}
                    {placeOrderResponse?.order_id}
                  </li>
                  {paymentId && (
                    <li>
                      <strong>Razorpay payment ID</strong> : {paymentId}
                    </li>
                  )}
                </ul>
              </div>
            ) : placeOrderResponse?.stock_id ? (
              `Some items in your cart seems to be out of stock. ${
                !!paymentId &&
                "If money has been deducted from your bank account it will be credited back within 2-3 business days."
              }`
            ) : paymentId ? (
              "If money has been deducted from your bank account it will be credited back within 2-3 business days"
            ) : (
              "Please try again later"
            ),
        });
        dispatch(cartActions.clearCart());
        setPlaceOrderLoading(false);
        window.woopra.track("order_placed", {
          url: window.location.pathname,
          title: document.title,
          fname,
          lname,
          phone,
          email,
        });
      } catch (error) {
        console.log("Error: ", error);
        setPlaceOrderStatus({
          status: "error",
          title: "There seems to be some issue while placing the order",
          subTitle: paymentId
            ? "If money has been deducted from your bank account it will be credited back within 2-3 business days"
            : "Pleas try again later",
        });
        setPlaceOrderLoading(false);
        window.woopra.track("order_place_error", {
          url: window.location.pathname,
          title: document.title,
          fname,
          lname,
          phone,
          email,
        });
      }
    },
    [dispatch, paymentMode]
  );

  const displayRazorpay = React.useCallback(
    async (
      totalInPaise: string,
      fname: string,
      lname: string,
      email: string,
      pincode: string,
      phone: string,
      address: string,
      city: string,
      state: string,
      total: string,
      items: any[]
    ) => {
      try {
        // load razorpay script
        await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        // create razorpay order
        const orderDetails = await createOrder(totalInPaise, "INR");
        const orderId = orderDetails.data;

        const options = {
          key: "rzp_live_D4YS36IugRxuPF", // Enter the Key ID generated from the Dashboard
          amount: totalInPaise, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Stupefiant", //your business name
          description: "Payment for order placed at Stupefiant",
          image: "https://thestupefiant.com/images/logos/stupefiant_logo_2.png",
          order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response: RazorpayPayment) {
            try {
              const paymentStatusResponse = await verifyPayment(
                orderId,
                response.razorpay_payment_id,
                response.razorpay_signature
              );
              const paymentStatus: PaymentStatus = paymentStatusResponse?.data;
              if (paymentStatus.success) {
                placeOrderUtil(
                  fname,
                  lname,
                  phone,
                  email,
                  pincode,
                  address,
                  city,
                  state,
                  total,
                  items,
                  response.razorpay_payment_id
                );
              } else {
                // order payment cannot be verified
                setPlaceOrderStatus({
                  status: ResponseStatusEnum.ERROR,
                  title: "Payment status verification failed",
                  subTitle: paymentStatus?.reason,
                });
                setPlaceOrderLoading(false);
              }
            } catch (error) {
              // Server error occured
              console.log("Error : ", error);
              setPlaceOrderStatus({
                status: ResponseStatusEnum.ERROR,
                title: "Server error occurred while verifying payment status",
              });
              setPlaceOrderLoading(false);
            }
          },
          modal: {
            ondismiss: () => {
              setPlaceOrderLoading(false);
              setCurrentStep(1);
            }
          },
          prefill: {
            //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            name: fname + lname, //your customer's name
            email: email,
            contact: phone, //Provide the customer's phone number for better conversion rates
          },
          notes: {
            address: address,
          },
          theme: {
            color: "#3399cc",
          },
        };

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.log("Error : ", error);
        setPlaceOrderStatus({
          status: ResponseStatusEnum.ERROR,
          title: "Failed to create Razorpay order",
          subTitle:
            "This might be due to script failed to load or some issue with the Orders API.",
        });
        setPlaceOrderLoading(false);
      }
    },
    [placeOrderUtil]
  );

  const placeOrder = React.useCallback(() => {
    const fname = contactInfo?.["first_name"] ?? "";
    const lname = contactInfo?.["last_name"] ?? "";
    const phone = contactInfo?.["phone_number"] ?? "";
    const email = contactInfo?.["email_id"] ?? "";
    const pincode = contactInfo?.["pincode"] ?? "";
    const address = contactInfo?.["address"] ?? "";
    const city = contactInfo?.["city"] ?? "";
    const state = contactInfo?.["state"] ?? "";
    const total = totalCharges?.toString() ?? "";
    const items = addedProductsInfo?.map((p) => {
      let total =
        cartProducts?.find(
          (product) =>
            product?.id === p?.productId && product?.stock_id === p?.stockId
        )?.price ?? 0;
      total = total * (p?.qty ?? 0);
      return {
        stock_id: p?.stockId,
        qty: p?.qty,
        total,
      };
    });
    setCurrentStep(2);
    setPlaceOrderLoading(true);
    window.woopra.track("checkout_page:place_order:click", {
      url: window.location.pathname,
      title: document.title,
      fname,
      lname,
      phone,
      email,
    });

    if (paymentMode === PaymentMode.ONLINE) {
      // create an order and recieve the order id
      // send the order id and open razorpay payment UI
      // after payment get the payment related data and send for verification
      // once verified show success or failure message to user
      const totalInPaise = (totalCharges * 100).toString();
      displayRazorpay(
        totalInPaise,
        fname,
        lname,
        email,
        pincode,
        phone,
        address,
        city,
        state,
        total,
        items
      );
    } else {
      placeOrderUtil(
        fname,
        lname,
        phone,
        email,
        pincode,
        address,
        city,
        state,
        total,
        items
      );
    }
  }, [
    contactInfo,
    totalCharges,
    addedProductsInfo,
    cartProducts,
    paymentMode,
    displayRazorpay,
    placeOrderUtil,
  ]);

  const proceedClick = React.useCallback(() => {
    window.woopra.track("checkout_page:proceed:click", {
      url: window.location.pathname,
      title: document.title,
    });
  }, []);

  const backBtnClick = React.useCallback(() => {
    setCurrentStep(0);
    window.woopra.track("checkout_page:back_btn:click", {
      url: window.location.pathname,
      title: document.title,
    });
  }, []);

  const userDetails = profileDetails?.user_details;
  const fname = userDetails?.fname;
  const lname = userDetails?.lname;
  const email = userDetails?.email;

  useEffect(() => {
    form.setFieldsValue({
      first_name: fname,
      last_name: lname,
      email_id: email,
    });
  }, [fname, lname, email, form]);

  return (
    <>
      {loading /* || !data*/ && <PageLoader />}
      {!loading /* && !!data*/ && (
        <>
          <Steps size="default" current={currentStep}>
            <Steps.Step title="Contact Information" icon={<ContactsFilled />} />
            <Steps.Step title="Payment" icon={<CreditCardFilled />} />
            <Steps.Step title="Order placed" icon={<CheckSquareFilled />} />
          </Steps>
          <br />
          <div style={{ display: currentStep === 0 ? "block" : "none" }}>
            {/* {!profileDetails?.loggedin && (
              <>
                <div className="checkout-login-btn-container">
                  <Link to={"/profile?redirect=checkout"}>
                    <Button>
                      <strong>Login / Sign up</strong>
                    </Button>
                  </Link>
                </div>
                <Divider>or</Divider>
              </>
            )} */}
            <Form
              {...formItemLayout}
              form={form}
              name="contact-info-form"
              onFinish={onFinishForm}
              layout={"vertical"}
              autoComplete="off"
            >
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Enter your first name",
                    type: "string",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="First name"
                  disabled={!!fname}
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Enter your last name",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Last name" disabled={!!lname} />
              </Form.Item>
              <Form.Item
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Enter your phone number",
                    pattern: new RegExp(/^[6-9]\d{9}$/gi),
                  },
                ]}
              >
                <InputNumber
                  placeholder="Phone (+91)"
                  style={{ width: "100%" }}
                  // disabled={!!data}
                />
              </Form.Item>
              <Form.Item
                name="email_id"
                rules={[
                  {
                    required: true,
                    message: "Enter your email id",
                    pattern: new RegExp(
                      //eslint-disable-next-line
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                  },
                ]}
              >
                <Input placeholder="Email" disabled={!!email} />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter the address",
                    type: "string",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="House / Flat / Block No. / Apartment / Road / Area / Landmark"
                  rows={3}
                />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Enter the City",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Enter the State",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="State" />
              </Form.Item>
              <Form.Item
                name="pincode"
                rules={[
                  {
                    required: true,
                    message: "Please enter your area pincode",
                    //eslint-disable-next-line
                    pattern: new RegExp(/^[1-9]{1}\d{2}\s?\d{3}$/gm),
                  },
                ]}
              >
                <InputNumber placeholder="Pincode" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={proceedClick}>
                  PROCEED
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: currentStep === 1 ? "block" : "none" }}>
            <Row>
              <Col xs={24} md={12}>
                <strong>ORDER DETAILS</strong>
                <table
                  style={{ marginTop: 10 }}
                  className="order-summary-table"
                >
                  <tbody>
                    {cartProducts?.map((product, index) => {
                      const qty =
                        addedProductsInfo?.find(
                          (p) =>
                            p?.productId === product?.id &&
                            p?.stockId === product?.stock_id
                        )?.qty ?? 0;
                      const price = product?.price;
                      return (
                        <tr key={index + 1}>
                          <th>
                            {product?.label} - {product?.size} x {qty}
                          </th>
                          <td>₹{price * qty}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <th>Sub Total</th>
                      <td>₹{subTotal}</td>
                    </tr>
                    <tr>
                      <th>Total (incl. of all taxes)</th>
                      <td>₹{totalCharges}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <br />
            <strong style={{ paddingBottom: 20 }}>PAYMENT MODE</strong>
            <br />
            <br />
            <img
              src="https://thestupefiant.com/images/payments/pay-online.png"
              alt=""
              width={320}
            />
            <br />
            <Radio.Group
              style={{ marginTop: 10 }}
              onChange={(e) => setPaymentMode(e.target.value)}
              value={paymentMode}
            >
              <Radio.Button value={PaymentMode.ONLINE}>Pay Online</Radio.Button>
              <Radio.Button value={PaymentMode.PAY_ON_DELIVERY}>
                Cash/Pay on Delivery
              </Radio.Button>
            </Radio.Group>
            <br />
            <br />
            <Button
              style={{ marginRight: 5 }}
              type="default"
              onClick={backBtnClick}
            >
              BACK
            </Button>
            <Button type="primary" onClick={placeOrder}>
              PLACE ORDER
            </Button>{" "}
          </div>
          <div style={{ display: currentStep === 2 ? "block" : "none" }}>
            {placeOrderLoading && <PageLoader />}
            {!placeOrderLoading && placeOrderStatus && (
              <Result
                status={placeOrderStatus?.status}
                title={placeOrderStatus?.title}
                subTitle={placeOrderStatus?.subTitle}
                extra={
                  placeOrderStatus?.status === ResponseStatusEnum.SUCCESS
                    ? [
                        <Link to="/">
                          <Button type="primary" key="home">
                            HOME
                          </Button>
                        </Link>,
                        <Link to="/shop">
                          <Button key="shop-more">SHOP MORE</Button>
                        </Link>,
                      ]
                    : placeOrderStatus?.status === ResponseStatusEnum.WARNING
                    ? [
                        <Link to="/cart">
                          <Button type="primary" key="cart">
                            GO TO CART
                          </Button>
                        </Link>,
                      ]
                    : [
                        <Link to="/">
                          <Button type="primary" key="home">
                            HOME
                          </Button>
                        </Link>,
                      ]
                }
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CheckoutPage;
