import React from "react";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <img src="/loading-logo.gif" alt="" height={50} width={50} />
    </div>
  );
};

export default React.memo(PageLoader);
