import { Card, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBestSellers } from "../../utils/homePageQueries";
import { Product } from "../Shop/Shop";

const BestSellers = () => {
  const navigate = useNavigate();
  const [bestsellers, setBestsellers] = useState<Product[]>([]);

  useEffect(() => {
    getBestSellers()
      .then((res) => {
        const productArr = res.data as Product[];
        setBestsellers(productArr);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const productCardClick = useCallback(
    (productId: number, productLabel: string) => {
      navigate(`/shop/${productId}`);
      window.woopra.track("home:bestsellers:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        productLabel,
      });
    },
    [navigate]
  );

  return (
    <div className="bestsellers-container">
      <div className="label">BEST SELLERS</div>
      <Row className="bestsellers">
        <Col xs={0} xl={4} />
        {bestsellers?.map((product) => (
          <Col
            key={product?.id}
            className="product-card-column"
            xs={12}
            sm={8}
            lg={6}
            xl={4}
          >
            <Card
              className="product-card"
              onClick={productCardClick.bind(null, product?.id, product?.label)}
              cover={
                <img
                  className="product-card-image"
                  src={product?.image_url}
                  alt=""
                />
              }
            >
              <div className="product-info-container">
                <strong className="small-font">{product?.label}</strong>
                <br />
                <strong className="small-font gray-text">
                  ₹{product?.price}
                </strong>
              </div>
            </Card>
          </Col>
        ))}
        <Col xs={0} xl={4} />
      </Row>
    </div>
  );
};

export default BestSellers;
