import { Button, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout, profile } from "../../utils/profileRelatedQueries";
import PageLoader from "../Loaders/PageLoader";
import Login from "./Login";
import Register from "./Register";

interface LogoutUserResponse {
  status: string;
}

interface UserDetails {
  fname?: string;
  lname?: string;
  email?: string;
}

interface UserOrder {
  id: number;
  status: string;
}

export interface UserProfile {
  loggedin: boolean;
  user_details?: UserDetails;
  user_orders?: UserOrder[];
}

const Profile = () => {
  const navigate = useNavigate();
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileDetails, setProfileDetails] = useState<UserProfile>();
  const [showRegister, setShowRegister] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const getProfileDetails = useCallback(() => {
    setProfileLoading(true);
    setProfileDetails(undefined);
    profile()
      .then((res) => {
        const userProfile: UserProfile = res?.data;
        setProfileDetails(userProfile);
        setProfileLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const showLogoutSuccess = useCallback(() => {
    api.success({ message: "Logged out successfully" });
  }, [api]);

  const showLogoutFailed = useCallback(() => {
    api.error({ message: "Failed to logout. Please try again" });
  }, [api]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    profileDetails?.loggedin &&
      queryParams?.get("redirect") === "checkout" &&
      navigate("/checkout");
  }, [profileDetails, navigate]);

  const logoutUser = useCallback(() => {
    setProfileLoading(true);
    logout()
      .then((res) => {
        const logoutResponse: LogoutUserResponse = res?.data;
        if (logoutResponse?.status === "success") {
          showLogoutSuccess();
          getProfileDetails();
        } else {
          showLogoutFailed();
          setProfileLoading(false);
        }
      })
      .catch((err) => console.log("here", err));
  }, [getProfileDetails, showLogoutSuccess, showLogoutFailed]);

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  const showRegisterHandler = useCallback(() => setShowRegister(true), []);
  const showLoginHandler = useCallback(() => setShowRegister(false), []);

  const showRegistrationSuccess = useCallback(() => {
    api.success({ message: "Registered successfully. Please login" });
  }, [api]);

  const showRegistrationFailed = useCallback(() => {
    api.error({
      message:
        "Registered failed. You may already be registered with us, please try to login",
    });
  }, [api]);

  const showLoginSuccess = useCallback(() => {
    api.success({ message: "Logged in successfully" });
  }, [api]);

  const showLoginFailed = useCallback(() => {
    api.error({ message: "Login failed. Email or password seems incorrect" });
  }, [api]);

  return (
    <>
      {contextHolder}
      {profileLoading && <PageLoader />}
      {!profileLoading && profileDetails?.loggedin && (
        <div className="profile-container">
          <h2>Profile</h2>
          <div>
            <strong>First name</strong>
            <br />
            <span>{profileDetails?.user_details?.fname}</span>
          </div>
          <div>
            <strong>Last name</strong>
            <br />
            <span>{profileDetails?.user_details?.lname}</span>
          </div>
          <div>
            <strong>Email</strong>
            <br />
            <span>{profileDetails?.user_details?.email}</span>
          </div>
          <h2>My Orders</h2>
          <table className="my-orders-table">
            {profileDetails?.user_orders &&
              profileDetails?.user_orders?.length > 0 && (
                <tr>
                  <td>
                    <strong>#</strong>
                  </td>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              )}
            {profileDetails?.user_orders?.map((order) => (
              <tr>
                <td>{order?.id}</td>
                <td>{order?.status ?? "CONFIRMED"}</td>
              </tr>
            ))}
            {profileDetails?.user_orders?.length === 0 && (
              <tr>
                <td colSpan={2}>Looks empty here!</td>
              </tr>
            )}
          </table>
          <br />
          <Button type="primary" onClick={logoutUser}>
            Logout
          </Button>
          <Button onClick={() => navigate("/")}>Go to home</Button>
        </div>
      )}
      {!profileLoading && !profileDetails?.loggedin && !showRegister && (
        <Login
          showRegisterHandler={showRegisterHandler}
          showLoginSuccess={showLoginSuccess}
          showLoginFailed={showLoginFailed}
          getProfileDetails={getProfileDetails}
        />
      )}
      {!profileLoading && !profileDetails?.loggedin && showRegister && (
        <Register
          showLoginHandler={showLoginHandler}
          showRegistrationSuccess={showRegistrationSuccess}
          showRegistrationFailed={showRegistrationFailed}
        />
      )}
    </>
  );
};

export default Profile;
