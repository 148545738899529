import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const AboutUs = () => {
  return (
    <Typography>
      <Title level={4}>About Us</Title>
      <Paragraph>
        We are Stupefiant an Unisex Streetwear Clothing & Apparel Brand which
        focuses solely on Design, Sustainability, Comfortability &
        Affordability.
      </Paragraph>
      <Paragraph>
        Stupéfiant is the combination of sustainability and style. Stupéfiant
        offers you with amazing unique looks to fill your wardrobe. Our main
        objective is to balance between style & substance which is to give you a
        poised look. Our designs are made solely to make you stand apart from
        the crowd.
      </Paragraph>
      <Paragraph>
        Our clothes are made of superior quality of fabrics and We make sure
        it's skin friendly. And it is purely MADE IN INDIA.
      </Paragraph>
      <Paragraph>
        Our relaxed and regular (UNIVERSAL SIZE) Fit keeps you comfortable at
        all times.
      </Paragraph>
      <Paragraph>
        Our Mission is to create an Amazing community who believes in
        sustainability and at the same time appreciates substance & style.
      </Paragraph>
    </Typography>
  );
};

export default AboutUs;
