import { Menu } from "antd";
import { MenuItem } from "rc-menu";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { UserProfile } from "../profile/Profile";
import CartIcon from "./CartIconMenu";

interface AppMenuItemsProps {
  closeMenu: () => void;
  profileDetails: UserProfile | undefined;
}

const computeSelectedNavLink = (path: string) => {
  if (path?.startsWith("/shop")) {
    return ["nav-link-2"];
  } else if (path?.startsWith("/cart")) {
    return ["nav-link-3"];
  } else if (path?.startsWith("/profile")) {
    return ["nav-link-4"];
  } else {
    return ["nav-link-1"];
  }
};

const AppMenuItems: React.FC<AppMenuItemsProps> = (props) => {
  const location = useLocation();

  const navLinkClick = React.useCallback((navLink: string) => {
    window.woopra.track(`menu:${navLink}:click`, {
      url: window.location.pathname,
      title: document.title,
    });
  }, []);

  return (
    <Menu
      theme="light"
      mode="vertical"
      selectedKeys={computeSelectedNavLink(location.pathname)}
      className="drawer-menu-items"
    >
      <MenuItem key="nav-link-1" onClick={props.closeMenu}>
        <Link to="/" onClick={navLinkClick.bind(null, "home_link")}>
          <div className="menu-nav-links">
            <span className="nav-link-text">HOME</span>
          </div>
        </Link>
      </MenuItem>
      <MenuItem key="nav-link-2" onClick={props.closeMenu}>
        <Link to="/shop" onClick={navLinkClick.bind(null, "shop_link")}>
          <div className="menu-nav-links">
            <span className="nav-link-text">SHOP</span>
          </div>
        </Link>
      </MenuItem>
      <MenuItem key="nav-link-3" onClick={props.closeMenu}>
        <Link to="/cart" onClick={navLinkClick.bind(null, "cart_link")}>
          <CartIcon />
        </Link>
      </MenuItem>
      <MenuItem key="nav-link-4" onClick={props.closeMenu}>
        <Link to="/profile" onClick={navLinkClick.bind(null, "profile_link")}>
          <div className="menu-nav-links">
            <span className="nav-link-text">
              {props.profileDetails?.loggedin ? "PROFILE" : "LOGIN / SIGN UP"}
            </span>
          </div>
        </Link>
      </MenuItem>
    </Menu>
  );
};

export default React.memo(AppMenuItems);
