import { Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux-utils/store";

const CartIconMenu = () => {
  const itemsCount = useSelector(
    (state: AppState) => state.cart?.items?.length
  );
  return (
    <div className="menu-nav-links">
      <span className="nav-link-text">BAG</span>
      <Badge
        count={itemsCount}
        size={"small"}
        style={{ background: "#ff4d4f", marginLeft: 7 }}
      />
    </div>
  );
};

export default React.memo(CartIconMenu);
