import Axios from "axios";

export const getProductImages = (id: number) => {
  return Axios.get(
    `https://thestupefiant.com/backend/get_product_images.php?product_id=${id}`
  );
};

export const getProductDetails = (id: number) => {
  return Axios.get(
    `https://thestupefiant.com/backend/get_product_details.php?product_id=${id}`
  );
};
