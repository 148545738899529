import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { getProductsForListingPage } from "../../utils/productsListingPageQueries";
import { useNavigate } from "react-router-dom";
import PageLoader from "../Loaders/PageLoader";

export interface Product {
  id: number;
  label: string;
  image_url: string;
  description?: string;
  price?: number;
}

const Shop = () => {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    getProductsForListingPage()
      .then((res) => {
        const products: Product[] = res.data;
        setProducts(products);
        setLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const productCardClick = React.useCallback(
    (productId: number, productLabel: string) => {
      navigate(`/shop/${productId}`);
      window.woopra.track("shop:product_card:click", {
        url: window.location.pathname,
        title: document.title,
        productId,
        productLabel,
      });
    },
    [navigate]
  );

  return (
    <>
      {loading && <PageLoader />}
      {!loading && (
        <Row>
          {products?.map((product) => (
            <Col
              key={product?.id}
              className="product-card-column"
              xs={12}
              sm={8}
              lg={6}
              xl={4}
            >
              <Card
                className="product-card"
                onClick={productCardClick.bind(
                  null,
                  product?.id,
                  product?.label
                )}
                cover={
                  <img
                    className="product-card-image"
                    src={product?.image_url}
                    alt=""
                  />
                }
              >
                <div className="product-info-container">
                  <strong className="small-font">{product?.label}</strong>
                  <br />
                  <strong className="small-font gray-text">
                    ₹{product?.price}
                  </strong>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default Shop;
